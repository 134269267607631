(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/usersettings/assets/javascripts/usersettings.js') >= 0) return;  svs.modules.push('/components/tur/usersettings/assets/javascripts/usersettings.js');


'use strict';

svs.tur = svs.tur || {};
svs.tur.usersettings = svs.tur.usersettings || {};
svs.tur.usersettings.logger = svs.tur.usersettings.logger || svs.core.log.getLogger('tur:usersettings');
const isPlayer = () => {
  const hasRolePlayer = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
  if (!hasRolePlayer) {
    svs.tur.usersettings.logger.info('User in not logged in.');
  }
  return hasRolePlayer;
};
svs.tur.usersettings.get = _ref => {
  let {
    key
  } = _ref;
  const deferred = $.Deferred();
  if (!key || !isPlayer()) {
    !key && svs.tur.usersettings.logger.info("GET cannot run without KEY");
    return deferred.reject({
      key
    });
  }
  svs.core.userSession.get(key, (error, result) => {
    if (error) {
      svs.tur.usersettings.logger.debug("GET key: ".concat(key, ", error: ").concat(error));
      return deferred.reject({
        key,
        error
      });
    }
    svs.tur.usersettings.logger.debug("GET key: ".concat(key, ", value: ").concat(result));
    return deferred.resolve({
      key,
      value: result
    });
  });
  return deferred.promise();
};
svs.tur.usersettings.set = _ref2 => {
  let {
    key,
    value
  } = _ref2;
  const deferred = $.Deferred();
  if (!key || value === undefined || !isPlayer()) {
    (!key || value === undefined) && svs.tur.usersettings.logger.info("SET cannot run without KEY and VALUE");
    return deferred.reject({
      key,
      value
    });
  }
  svs.core.userSession.set(key, value, error => {
    if (error) {
      svs.tur.usersettings.logger.debug("SET key: ".concat(key, ", error: ").concat(error));
      return deferred.reject({
        key,
        error
      });
    }
    svs.tur.usersettings.logger.debug("SET key: ".concat(key, ", value: ").concat(value));
    return deferred.resolve({
      key,
      value
    });
  });
  return deferred.promise();
};
svs.tur.usersettings.remove = _ref3 => {
  let {
    key
  } = _ref3;
  const deferred = $.Deferred();
  if (!key || !isPlayer()) {
    !key && svs.tur.usersettings.logger.info("REMOVE cannot run without KEY");
    deferred.reject({
      key
    });
  }
  svs.core.userSession.remove(key, error => {
    if (error) {
      svs.tur.usersettings.logger.debug("REMOVE key: ".concat(key, ", error: ").concat(error));
      deferred.reject({
        key,
        error
      });
    } else {
      svs.tur.usersettings.logger.debug("REMOVE key: ".concat(key, " Removed"));
      deferred.resolve({
        key
      });
    }
  });
  return deferred.promise();
};

 })(window);